.k-item {
  height: 4rem;
  width: 18rem;
  background: white;
}

.k-tabstrip-items-wrapper {
  overflow-x: auto;
}

.k-state-active::after {
  border: none !important;
}

.k-animation-container {
  width: 100%;
}

.k-tabstrip > .k-content {
  padding: 0;
}

.k-radio-item {
  margin-top: 0px !important;
}

